import { Brand } from '../shop/types/brand';
import { EshopLanguage } from '../shop/types/eshop-language';

export function getPrivacyPolicyUrl(brand: Brand, language: EshopLanguage) {

	switch (brand) {

		case 'oj':

			switch (language) {
				case 'cs':
				case 'sk':
					return 'https://www.onlinejazyky.cz/ochrana-osobnich-udaju.html';

				default:
					return 'https://easylingo.com/en/privacy-policy/';
			}

		case 'el':

			switch (language) {
				case 'cs':
					return 'https://easylingo.com/cs/ochrana-osobnich-udaju/';

				case 'sk':
					return 'https://easylingo.com/sk/zasady-ochrany-osobnych-udajov/';

				case 'pl':
					return 'https://easylingo.com/pl/zasady-ochrany-osobnych-udajov/';

				case 'es':
					return 'https://easylingo.com/es/politica-de-privacidad/';

				default:
					return 'https://easylingo.com/en/privacy-policy/';
			}


		default:
			return 'https://easylingo.com/en/privacy-policy/';

	}

}
