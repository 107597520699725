import {Lesson} from '../../../oj-app-common/course-structure/models/lesson';
import {LESSON_TYPE} from '../../../oj-app-common/course-structure/models/lesson-type.enum';

export function getLessonStatusSymbolName(lesson: Lesson, currentLesson: Lesson, currentLessonId: number = null): string {

  if (currentLesson && lesson.id === currentLesson.id) {
    return 'current';
  }

  if (currentLessonId && lesson.id === currentLessonId) {
    return 'current';
  }

  if (lesson.status) {
    if (lesson.status.score > 75 && lesson.status.totalAnswers > 0) {
      return 'success';
    } else if (lesson.status.score >= 0 && lesson.status.totalAnswers > 0) {
      return 'not-success';
    } else if (lesson.status.visited) {
      return 'visited';
    } else if (!lesson.status.totalAnswers && lesson.type === LESSON_TYPE.EXERCISE) {
      // https://podio.com/onlinejazykycz/zakladna-oj/apps/hlaseni/items/14102
      return 'other';
    }
  }

  if (lesson.visited) {
    return 'visited';
  } else {
    return 'other';
  }

}
